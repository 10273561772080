import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { companyClient } from "../assets/js/helpers"

import inSocialIcon from "../assets/images/company/social-in.svg"
import igSocialIcon from "../assets/images/company/social-ig.svg"

import shape1 from "../assets/images/vector/company/shape-1.svg"
import shape2 from "../assets/images/vector/company/shape-2.svg"
import shape3 from "../assets/images/vector/company/shape-3.svg"
import shape4 from "../assets/images/vector/company/shape-4.svg"
import shape5 from "../assets/images/vector/company/shape-5.svg"
import shape6 from "../assets/images/vector/company/shape-6.svg"
import shape7 from "../assets/images/vector/company/shape-7.svg"

import banner from "../assets/images/company/company-banner-image.png"
import companySpringkrafStart from "../assets/images/company/company-springkraf-start.jpg"
import companyTeam from "../assets/images/company/company-team.jpg"
import companyProgress from "../assets/images/company/company-progress.jpg"
import companyLocation from "../assets/images/company/company-location.jpg"

function CompanyPage() {
  return (
    <Layout>
      <SEO
        title="Company"
        description="We do work with ❤️. We know that every work needs to be done in earnest, from the most simple to the most complex. Our team loves their work, and we always believe that we will grow if our products can benefit you. We are all ready to grow with you"
      />
      <section className="company-banner section-top-200 section-bottom-100 text-center text-md-left">
        <div className="container">
          <div className="row">
            <div className="col-md-7 order-2 order-md-1">
              <div>
                <div className="subtitle color-pure-white m-0">Springkraf</div>
                <div className="title color-pure-white">We do work with ❤️</div>
                <div className="description color-pure-white">
                  We know that every work needs to be done in earnest, from the
                  most simple to the most complex. Our team loves their work,
                  and we always believe that we will grow if our products can
                  benefit you. We are all ready to grow with you
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-4 mb-md-0 order-1 order-md-2">
              <img
                style={{ width: "100%" }}
                alt="Springkraf company banner image"
                src={banner}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="p-relative section-150">
        <span className="landing-shape-4">
          <img src={shape1} />
        </span>
        <span className="landing-shape-5">
          <img src={shape2} />
        </span>
        <span className="company-shape-3">
          <img src={shape3} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-4 mb-md-0">
              <div className="title color-cerulean-blue">
                Why we start Springkraf
              </div>
              <div className="description">
                <p>
                  We started sprinting because we believe that we could help
                  more businesses grow by utilizing technology. We found much
                  competition before we started, and yes! Like most people, we
                  begin with little hope.
                </p>
                <p>
                  However, we keep sprinting, and there is a reason that makes
                  us survive and go this far, is when we are experiencing our
                  products useful for people who believe in our work.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image"
                alt="Springkraf company banner image"
                src={companySpringkrafStart}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="p-relative section-100">
        <span className="landing-shape-4">
          <img src={shape4} />
        </span>
        <span className="company-shape-5">
          <img src={shape5} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                alt="Springkraf company banner image"
                src={companyTeam}
              />
            </div>
            <div className="col-md-7">
              <div className="title color-cerulean-blue">Who we are</div>
              <div className="description">
                <p>
                  The team was founded by Denny, Erwin & Jemmy, who were a
                  passionate web & mobile programmer. Not that long when they
                  start building this company in their free time they decided to
                  leave their jobs from leading startups, now they begin to
                  focus on giving more technology services by creating a bigger
                  & stronger team. The team is not often known for their work,
                  but also their responsibility as a third-party IT supporting
                  division.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="p-relative section-100"
        style={{ overflow: "hidden" }}
      >
        <span className="portfolio-wave-1 company-wave" />
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-4 mb-md-0">
              <div className="title color-cerulean-blue">
                Our progress so far
              </div>
              <div className="description">
                <p>
                  We started in 2017. There were many small and big obstacles,
                  but we always compact and proud of our work. It does not mean
                  we will be arrogant and do not accept opinions, and we will
                  still serve and consult by putting aside the ego. Since then,
                  we have been trusted by our clients and become an essential
                  part of their businesses.
                </p>
              </div>
              <div className="d-flex text-center font-bebas justify-content-center justify-content-md-start mt-4 pr-0 pr-sm-4 pr-md-5">
                <div className="d-flex flex-column justify-content-center mx-auto mx-sm-4 mx-md-5">
                  <div
                    className="color-cerulean-blue"
                    style={{ fontSize: 62, letterSpacing: ".1em" }}
                  >
                    02
                  </div>
                  <div style={{ fontSize: 20 }}>
                    YEARS IN
                    <br />
                    BUSINESS
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center mx-auto mx-sm-4 mx-md-5">
                  <div
                    className="color-cerulean-blue"
                    style={{ fontSize: 62, letterSpacing: ".1em" }}
                  >
                    11
                  </div>
                  <div style={{ fontSize: 20 }}>
                    PROJECTS
                    <br />
                    COMPLETED
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center mx-auto mx-sm-4 mx-md-5">
                  <div
                    className="color-cerulean-blue"
                    style={{ fontSize: 62, letterSpacing: ".1em" }}
                  >
                    08
                  </div>
                  <div style={{ fontSize: 20 }}>
                    CLIENTS
                    <br />
                    SERVED
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image"
                alt="Springkraf company banner image"
                src={companyProgress}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="p-relative section-100">
        <span className="landing-shape-2">
          <img src={shape6} />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img
                className="portfolio-image"
                alt="Springkraf company banner image"
                src={companyLocation}
              />
            </div>

            <div className="col-md-7 mb-4 mb-md-0">
              <div className="title color-cerulean-blue">Our Location</div>
              <div className="description">
                <p>
                  Our team located in Medan, Indonesia. Medan is the
                  third-largest city in Indonesia after Jakarta and Surabaya, as
                  well as the largest city outside ofJava. Medan is the gateway
                  to the western part of Indonesia with the presence of Belawan
                  Port and Kuala Namu International Airport, which is the
                  secondlargest airport in Indonesia.
                </p>
                <p>
                  Toll roads and trains complement access from the city center
                  to the harbor and airport. Medan is the first city in
                  Indonesia to integrate airports with trains. Bordering the
                  Malacca Strait makes Medan a vital trade, industry, and
                  business city in Indonesia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-relative section-100">
        <span className="company-shape-5">
          <img src={shape7} />
        </span>
        <div className="container text-center">
          <div className="title color-cerulean-blue">Whom We Work With</div>
          <div className="description">
            Think of technology as a vehicle to accelerate the running of your
            business, so what business does not require technology? Almost all
            companies need a technology division. Therefore our business clients
            come from many types of companies as below:
          </div>

          <div className="section-50">
            <div className="landing-service">
              {companyClient.map((value, key) => {
                return (
                  <div key={key} className="landing-service-item">
                    <img src={value.image} />
                    <div className="landing-service-item-description">
                      {value.title}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section-100">
        <div className="container">
          <div className="text-center title color-cerulean-blue">
            Meet Our Leaders
          </div>
          <div className="d-flex flex-column align-items-center text-center">
            <div className="leader-greeting">
              "Do not know then do not love"
            </div>
            <div className="leader-subtitle subtitle">
              We are just people who are enthusiastic about beautifying the
              world with the capabilities we have. If later we have other
              abilities, let's see what we can contribute to the world.
            </div>
          </div>

          <div className="leader-profiles my-5">
            <div className="leader-profile leader-denny">
              <div className="leader-image">
                <div className="leader-socials">
                  <a
                    className="social-item"
                    href="https://www.linkedin.com/in/dennysutomo/"
                  >
                    <img src={inSocialIcon} />
                  </a>
                  <a
                    className="social-item"
                    href="https://www.instagram.com/dennysutomo_/"
                  >
                    <img src={igSocialIcon} />
                  </a>
                </div>
              </div>
              <div className="heading">Denny Sutomo</div>
              <div className="font-palanquin color-cerulean-blue">
                Co-founder of Springkraf
              </div>
            </div>

            <div className="leader-profile leader-jemmy">
              <div className="leader-image">
                <div className="leader-socials">
                  <a
                    className="social-item"
                    href="https://www.linkedin.com/in/jemmy-hasjem-a234b8109/"
                  >
                    <img src={inSocialIcon} />
                  </a>
                  <a
                    className="social-item"
                    href="https://www.instagram.com/jemmyphan/"
                  >
                    <img src={igSocialIcon} />
                  </a>
                </div>
              </div>
              <div className="heading">Jemmy Phan</div>
              <div className="font-palanquin color-cerulean-blue">
                Co-founder of Springkraf
              </div>
            </div>

            <div className="leader-profile leader-erwin">
              <div className="leader-image">
                <div className="leader-socials">
                  <a
                    className="social-item"
                    href="https://www.linkedin.com/in/rwinzhang/"
                  >
                    <img src={inSocialIcon} />
                  </a>
                  <a
                    className="social-item"
                    href="https://www.instagram.com/rwinzhang/"
                  >
                    <img src={igSocialIcon} />
                  </a>
                </div>
              </div>
              <div className="heading">Erwin</div>
              <div className="font-palanquin color-cerulean-blue">
                Co-founder of Springkraf
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Have a chat with us!
            </div>
            <div className="mb-5 color-spring-green">
              Discuss all of your digital needs and start build your business.
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CompanyPage
